import React, { Component, Fragment } from "react";
import { Icon } from "@iconify/react";
import {
  ClearSessionItems,
  LocalStorage,
  getCurrentLocation,
} from "../helper/appsession";
import { axiosPost } from "../apis/useAxios";
import { LoginAssetType } from "../helper/fixcode";
import ChangePasswordComponent from "../components/Shared/ChangePasswordComponent";
import * as moment from "moment";

const TIME_FORMAT = "ddd DD, MMM h:mm:ss A";

export class TopBarComponent extends Component {
  static displayName = TopBarComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      changePassword: {
        renderChangePasswordModal: false,
        userId: 0,
        userName: "",
      },
      currentTime: moment().format(TIME_FORMAT),
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({
        currentTime: moment().format(TIME_FORMAT),
      });
    }, 1000);
  }

  handleOnLogout = async (e) => {
    e.preventDefault();
    try {
      let userId = LocalStorage.UserId;
      const { lat, lng } = await getCurrentLocation();
      let url = `/authenticate/user/${userId}/logout`;
      const response = await axiosPost(url, {
        SessionId: LocalStorage.SessionId,
        AssetTypeId: LoginAssetType.Web,
        Latitude: lat,
        Longitude: lng,
      });
      if (response.success) {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
    ClearSessionItems();
    window.location.href = "/";
  };
  handleOnCloaseChangePasswordModel = () => {
    try {
      let changePassword = this.state.changePassword;
      changePassword.renderChangePasswordModal = false;
      changePassword.userId = 0;
      changePassword.userName = "";
      this.setState((prev) => ({ ...prev, changePassword: changePassword }));
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    return (
      <Fragment>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <img src="dist/img/menu-icon.svg" width={28} />
              </a>
            </li>
            <li className="nav-item">
              <h5 className="mb-0" id="lblPageTitle"></h5>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">            
            {/* <li>
              <a
                className="nav-link"
                data-widget="fullscreen"
                href="/ameyotoolbar"
                role="button"
              >
                <Icon
                  icon="zondicons:dial-pad"
                  width="18"
                  height="18"
                  className="btn-link"
                />
              </a>
            </li> */}
            <li>
              <small>
                Welcome <b>{LocalStorage.UserName}!</b>
              </small>
            </li>
            <li>
              <small>
                &nbsp;&nbsp;Logged-in as <b>[{LocalStorage.UserTypeName}]</b>
              </small>&nbsp;&nbsp;&nbsp;&nbsp;
            </li>
            <li>
              <i className="fas fa-calendar-day topbar-clockicon"></i>{" "}
              <span className="topbar-clock">{this.state.currentTime}</span>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="fullscreen"
                href="#"
                role="button"
              >
                <i className="fas fa-expand-arrows-alt"></i>
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <Icon icon="tdesign:user" />
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <div className="media p-2">
                  <img
                    className="mr-3 usericon"
                    src="../dist/img/avatar.png"
                    alt="User Icon"
                  />
                  <div className="media-body">
                    <small>
                      Logged-in as <br />
                      <b>{LocalStorage.UserTypeName}</b>
                    </small>
                    <hr />
                    <p>
                      {LocalStorage.FirstName && (
                        <>
                          <strong>
                            {LocalStorage.FirstName} {LocalStorage.LastName}
                          </strong>
                          <br />
                        </>
                      )}
                      <small>
                        <Icon icon="fa:user" /> {LocalStorage.UserName}
                      </small>
                      {LocalStorage.Email && (
                        <>
                          <br />
                          <Icon icon="material-symbols:mail" />{" "}
                          <small>{LocalStorage.Email}</small>
                        </>
                      )}
                      {LocalStorage.PhoneNumber && (
                        <>
                          <br />
                          <Icon icon="fluent:phone-32-filled" />{" "}
                          <small>{LocalStorage.PhoneNumber}</small>
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <hr />
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => this.handleOnLogout(e)}
                >
                  <Icon icon="material-symbols:logout" /> Logout{" "}
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    let changePassword = this.state.changePassword;
                    changePassword.renderChangePasswordModal = true;
                    changePassword.userId = LocalStorage.UserId;
                    changePassword.userName = LocalStorage.UserName;
                    this.setState((prev) => ({
                      ...prev,
                      changePassword: changePassword,
                    }));
                  }}
                >
                  <Icon icon="material-symbols:key" /> Change Password
                </a>
              </div>
            </li>
          </ul>
        </nav>
        {this.state.changePassword.renderChangePasswordModal && (
          <ChangePasswordComponent
            userId={this.state.changePassword.userId}
            userName={this.state.changePassword.userName}
            onClose={this.handleOnCloaseChangePasswordModel}
          />
        )}
      </Fragment>
    );
  }
}
